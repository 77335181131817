import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import useData from "../../../hook/useData";
import { formatToDateString } from "../../../utils/formatDate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import StyledTableColumn from "@mui/material/TableContainer";
import { Box, CircularProgress, Paper, styled } from "@mui/material";
import StyledTableHeader from "../EngagementChart/StyledTableHeader/StyledTableHeader";
import Loader from "../../Loader/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "HKGrotesk",
    border: "1px solid",
    borderColor: "#EBEBEB",
  },
}));

const ctaHeaders = [
  "CTA  ",
  "CLICKS",
  "ENGAGED LEADS",
  "SALES",
  "SERVICE",
  "SALES CONVERSION",
];

const CtaPerformanceChart = () => {
  const dateRange = useSelector((state) => state.date);

  const [activeSortingIndex, setActiveSortingIndex] = useState(0);
  const [ascSortingDirection, setAscSortingDirection] = useState(true);

  const {
    data: ctaData,
    status: ctaStatus,
    error: ctaError,
    statusCode: ctaCode,
  } = useData(
    "dealerChat_performance_cta_metrics_table",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const handleRequestSort = (newIndex) => {
    if (activeSortingIndex === newIndex) {
      setAscSortingDirection((prevState) => !prevState); // Toggle sort order
    } else {
      setActiveSortingIndex(newIndex);
      setAscSortingDirection(true);
    }
  };

  const sortedRows = () => {
    const sources = ["direct", "external"];

    if (!ctaData) return sources;

    return sources?.sort((a, b) => {
      const key = Object?.keys(ctaData)[activeSortingIndex - 1]; // Get column key
      const valueA = ctaData[key]?.[a]?.count || 0;
      const valueB = ctaData[key]?.[b]?.count || 0;
      return ascSortingDirection ? valueA - valueB : valueB - valueA;
    });
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
      backgroundColor: "white",
      height: "56px !important",
    },
  }));

  const getColumnName = (col) => {
    if (col === "direct") {
      return "CHAT BUTTON ";
    }
    return "OTHER";
  };

  const renderTableRows = () => {
    console.log("sortedRows", sortedRows(), ctaData);
    return sortedRows().map((source, index) => (
      <TableRow key={index}>
        <TableCell
          align="left"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            textTransform: "uppercase",
          }}
        >
          {source && getColumnName(source)}
        </TableCell>
        {ctaData &&
          Object?.keys(ctaData)?.map((key) => (
            <StyledTableCell key={key} align="left">
              {ctaData[key][source]?.count || 0}
              {ctaData[key][source]?.percentage
                ? ` (${Math.round(ctaData[key][source]?.percentage)}%)`
                : ""}
            </StyledTableCell>
          ))}
      </TableRow>
    ));
  };

  if (ctaStatus === "pending") {
    return (
      <TableContainer
        component={Paper}
        sx={{
          minHeight: 160,
          borderRadius: "8px",
          boxShadow: "0px 0px 26px #00000026",
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={ctaHeaders}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
          <Loader />
        </Table>
      </TableContainer>
    );
  }

  return (
    <div>
      {" "}
      <TableContainer
        component={Paper}
        sx={{
          minHeight: 160,
          borderRadius: "8px",
          boxShadow: "0px 0px 26px #00000026",
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={ctaHeaders}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CtaPerformanceChart;
