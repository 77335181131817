import { useSelector } from "react-redux";
import { useState } from "react";
import useData from "../../../hook/useData";
import { formatToDateString } from "../../../utils/formatDate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Paper, styled } from "@mui/material";
import StyledTableHeader from "../EngagementChart/StyledTableHeader/StyledTableHeader";
import Loader from "../../Loader/Loader";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: "12px",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "HKGrotesk",
    border: "1px solid",
    borderColor: "#EBEBEB",
  },
}));
const utmHeaders = [
  "SOURCE",
  "VISITORS",
  "OPENS",
  "ENGAGED LEADS",
  "SALES",
  "SERVICE",
  "SALES CONVERSION",
];

const SourcePerformaceChart = () => {
  const dateRange = useSelector((state) => state.date);

  const [activeSortingIndex, setActiveSortingIndex] = useState(0);
  const [ascSortingDirection, setAscSortingDirection] = useState(true);

  const {
    data: utmData,
    status: utmStatus,
    error: utmError,
    statusCode: utmCode,
  } = useData(
    "dealerChat_performance_utm_metrics_table",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange]
  );

  const sortData = () => {
    if (!utmData) return utmData;

    const headersKeyMapping = [
      "source", // Example mapping for "SOURCE"
      "visitors",
      "opens",
      "engaged_leads",
      "sales",
      "service",
      "sales_conversion",
    ];

    const key = headersKeyMapping[activeSortingIndex];
    const sortedData = { ...utmData };

    Object.keys(sortedData).forEach((metric) => {
      sortedData[metric] = Object.keys(sortedData[metric])
        .sort((a, b) => {
          const valueA = sortedData[metric][a]?.[key]?.count || 0;
          const valueB = sortedData[metric][b]?.[key]?.count || 0;

          return ascSortingDirection ? valueA - valueB : valueB - valueA;
        })
        .reduce((acc, curr) => {
          acc[curr] = sortedData[metric][curr];
          return acc;
        }, {});
    });

    return sortedData;
  };

  const handleRequestSort = (newIndex) => {
    if (activeSortingIndex === newIndex) {
      setAscSortingDirection((prevState) => !prevState); // Toggle order
    } else {
      setActiveSortingIndex(newIndex);
      setAscSortingDirection(true);
    }
  };

  const sortedRows = () => {
    const sources = ["direct", "external"];

    if (!utmData) return sources;

    return sources?.sort((a, b) => {
      const key = Object?.keys(utmData)[activeSortingIndex - 1]; // Get selected column
      const valueA = utmData[key]?.[a]?.count || 0;
      const valueB = utmData[key]?.[b]?.count || 0;
      return ascSortingDirection ? valueA - valueB : valueB - valueA;
    });
  };

  const renderTableRows = () => {
    return sortedRows().map((source, index) => (
      <TableRow key={index}>
        <TableCell
          align="left"
          sx={{
            fontWeight: "bold",
            fontSize: "12px",
            textTransform: "uppercase",
            minWidth: "120px",
          }}
        >
          {source.charAt(0).toUpperCase() + source.slice(1)}
        </TableCell>
        {utmData &&
          Object?.keys(utmData)?.map((key) => (
            <StyledTableCell key={key} align="left">
              {utmData[key][source]?.count || 0}
              {utmData[key][source]?.percentage
                ? ` (${Math.round(utmData[key][source]?.percentage)}%)`
                : ""}
            </StyledTableCell>
          ))}
      </TableRow>
    ));
  };

  if (utmStatus === "pending") {
    return (
      <TableContainer
        component={Paper}
        sx={{
          minHeight: 160,
          borderRadius: "8px",
          boxShadow: "0px 0px 26px #00000026",
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={utmHeaders}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
          <Loader />
        </Table>
      </TableContainer>
    );
  }

  return (
    <div>
      {" "}
      <TableContainer
        component={Paper}
        sx={{
          minHeight: 160,
          borderRadius: "8px",
          boxShadow: "0px 0px 26px #00000026",
        }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <StyledTableHeader
            headers={utmHeaders}
            onSortRequest={handleRequestSort}
            activeSortingIndex={activeSortingIndex}
            ascSortingDirection={ascSortingDirection}
          />
          <TableBody>{renderTableRows()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SourcePerformaceChart;
