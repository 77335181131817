import CtaPerformanceChart from "./CtaPerformanceChart/CtaPerformanceChart";
import DashboardCards from "./DashboardCards/DashboardCards";
import "./DashboardDealerChat.scss";
import EngagementChart from "./EngagementChart/EngagementChart";
import SourcePerformanceChart from "./SourcePerformanceChart/SourcePerformaceChart";

const salesDescription =
  "Shoppers that were handed off to their assigned sales rep. Handoffs Awaiting Sales Response are shoppers that claim they have not yet been contacted by a sales rep and require immediate attention. Handoffs Worked are shoppers that claim they were successfully contacted by a sales rep after a handoff";

const DashboardDealerChat = () => {
  return (
    <div className="Dashboard">
      <div className="DashboardPanel">
        <h2 className="DashboardPanel-title"></h2>
        <DashboardCards />
      </div>
      <div className="DashboardPanel DashboardPanel--charts">
        <h2 className="DashboardPanel-title DashboardPanel-definition">
          <span
            style={{
              paddingRight: "15px",
              paddingBottom: "20px",
              fontSize: "2rem",
              fontWeight: 500,
            }}
          >
            Chat Performance by Source
          </span>{" "}
        </h2>
        <SourcePerformanceChart />
      </div>
      <div className="DashboardPanel DashboardPanel--charts">
        <h2 className="DashboardPanel-title DashboardPanel-definition">
          <span
            style={{
              paddingRight: "15px",
              paddingBottom: "20px",
              fontSize: "2rem",
              fontWeight: 500,
            }}
          >
            Chat Performance by CTA
          </span>{" "}
        </h2>
        <CtaPerformanceChart />
      </div>
      {/* <div className="DashboardPanel DashboardPanel--charts">
        <h2 className="DashboardPanel-title DashboardPanel-definition">
          <span style={{ paddingRight: "15px", paddingBottom: "20px" }}>
            Engagement Chart
          </span>{" "}
        </h2>
        <EngagementChart />
      </div> */}
    </div>
  );
};

export default DashboardDealerChat;
