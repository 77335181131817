import { useSelector } from "react-redux";
import { formatToDateString } from "../../../utils/formatDate";
import useData from "../../../hook/useData";
import "./DashboardCards.scss";
import DashboardCard from "../Card/Card";
import { useEffect } from "react";

const DashboardCards = () => {
  const dateRange = useSelector((state) => state.date);
  const startDate = formatToDateString(dateRange.startDate);
  const endDate = formatToDateString(dateRange.endDate);
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );

  const {
    data: total_website_visitors_data,
    status: total_website_visitors_status,
    error: total_website_visitors_error,
    statusCode: total_website_visitors_statusCode,
  } = useData(
    "total_website_visitors",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange]
  );

  const {
    data: dealership_takeover_status_data,
    status: dealership_takeover_status_status,
    error: dealership_takeover_status_error,
  } = useData(
    "getDealershipTakeoverStatus",
    {
      account_id: accountId,
    },
    [accountId],
    true
  );

  const {
    data: serviceSchedulerStatusData,
    status: serviceSchedulerStatusStatus,
    error: serviceSchedulerStatusError,
    execute: serviceSchedulerStatusExecute,
  } = useData(
    "getChatServiceSchedulerStatus",
    {
      account_id: accountId,
    },
    [accountId],
    true
  );

  const {
    data: dealership_takeover_leads_Data,
    status: dealership_takeover_leads_Status,
    error: dealership_takeover_leads_Error,
    statusCode: dealership_takeover_leads_StatusCode,
    execute: fetch_dealership_takeover_leads,
  } = useData(
    "dealership_takeover_leads",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange],
    false
  );

  //ai_set_appointments
  const {
    data: total_chat_leads_worked_Data,
    status: total_chat_leads_worked_Status,
    error: total_chat_leads_worked_Error,
    statusCode: total_chat_leads_worked_statusCode,
  } = useData(
    "total_chat_leads_worked",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange]
  );

  const {
    data: chat_lead_conversation_rateInCRM_Data,
    status: chat_lead_conversation_rateInCRM_Status,
    error: chat_lead_conversation_rateInCRM_Error,
    statusCode: chat_lead_conversation_rateInCRM_statusCode,
  } = useData(
    "chat_lead_conversation_rateInCRM",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange]
  );

  const {
    data: service_appointments_Data,
    status: service_appointments_Status,
    error: service_appointments_Error,
    statusCode: service_appointments_statusCode,
    execute: fetch_service_appointments,
  } = useData(
    "service_appointments",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange],
    false
  );

  const {
    data: ai_set_appointments_Data,
    status: ai_set_appointments_Status,
    error: ai_set_appointments_Error,
    statusCode: ai_set_appointments_statusCode,
  } = useData(
    "ai_set_appointments",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange]
  );

  const {
    data: lead_events_registered_Data,
    status: lead_events_registered_Status,
    error: lead_events_registered_Error,
    statusCode: lead_events_registered_statusCode,
  } = useData(
    "lead_events_registered",
    {
      start_date: startDate,
      end_date: endDate,
    },
    [dateRange]
  );

  useEffect(async () => {
    if (dealership_takeover_status_data?.Status) {
      await fetch_dealership_takeover_leads();
    }
    if (serviceSchedulerStatusData?.Status) {
      await fetch_service_appointments();
    }
  }, [dealership_takeover_status_data, serviceSchedulerStatusData, dateRange]);

  return (
    <div className="DashboardCardArea">
      <DashboardCard
        type="square"
        objectKey="total_website_visitors"
        status={total_website_visitors_status}
        error={total_website_visitors_error}
        dataPrimary={total_website_visitors_data}
        statusCode={total_website_visitors_statusCode}
      />
      <DashboardCard
        type="square"
        objectKey="total_chat_leads_worked"
        status={total_chat_leads_worked_Status}
        error={total_chat_leads_worked_Error}
        dataPrimary={total_chat_leads_worked_Data}
        statusCode={total_chat_leads_worked_statusCode}
      />
      <DashboardCard
        type="square"
        objectKey="chat_lead_conversation_rate_in_crm"
        status={chat_lead_conversation_rateInCRM_Status}
        error={chat_lead_conversation_rateInCRM_Error}
        dataPrimary={chat_lead_conversation_rateInCRM_Data}
        statusCode={chat_lead_conversation_rateInCRM_statusCode}
      />
      {serviceSchedulerStatusData?.Status && (
        <DashboardCard
          type="square"
          objectKey="service_appointments"
          status={service_appointments_Status}
          error={service_appointments_Error}
          dataPrimary={service_appointments_Data}
          statusCode={service_appointments_statusCode}
        />
      )}
      <DashboardCard
        type="square"
        objectKey="ai_set_appointments"
        status={ai_set_appointments_Status}
        error={ai_set_appointments_Error}
        dataPrimary={ai_set_appointments_Data}
        statusCode={ai_set_appointments_statusCode}
      />
      {dealership_takeover_status_data?.Status && (
        <DashboardCard
          type="square"
          objectKey="total_dealership_takeovers"
          status={dealership_takeover_leads_Status}
          error={dealership_takeover_leads_Error}
          dataPrimary={dealership_takeover_leads_Data}
          statusCode={dealership_takeover_leads_StatusCode}
        />
      )}
      <div className="twinCardContainer">
        <DashboardCard
          type="twin"
          renderType="dealerChat_registered"
          status={lead_events_registered_Status}
          error={lead_events_registered_Error}
          dataPrimary={lead_events_registered_Data}
          statusCode={lead_events_registered_statusCode}
        />
      </div>
    </div>
  );
};

export default DashboardCards;
