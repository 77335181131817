import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Tile from "../../Tile/Tile";
import { formatToDateString } from "../../../utils/formatDate";
import useData from "../../../hook/useData";

import { chooseConversationsEndpoint } from "../../../store/actions/conversationActions";

import "./DashboardCards.scss";
import DashboardCard from "../../Card/Card";

const DashboardCards = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accountId = useSelector(
    (state) => state.user.auth?.data?.user_info?.DealerId
  );
  const dateRange = useSelector((state) => state.date);

  //
  const {
    data: total_leads_worked_data,
    status: total_leads_worked_status,
    error: total_leads_worked_error,
    statusCode: total_leads_worked_statusCode,
  } = useData(
    "total_leads_worked",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  //ai_set_appointments
  const {
    data: total_messages_sent_Data,
    status: total_messages_sent_Status,
    error: total_messages_sent_Error,
    statusCode: total_messages_sent_statusCode,
  } = useData(
    "total_messages_sent",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  const {
    data: existing_leads_engaged_Data,
    status: existing_leads_engaged_Status,
    error: existing_leads_engaged_Error,
    statusCode: existing_leads_engaged_statusCode,
  } = useData(
    "existing_leads_engaged",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  const {
    data: new_leads_engaged_Data,
    status: new_leads_engaged_Status,
    error: new_leads_engaged_Error,
    statusCode: new_leads_engaged_statusCode,
  } = useData(
    "new_leads_engaged",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  const {
    data: ai_set_appointments_Data,
    status: ai_set_appointments_Status,
    error: ai_set_appointments_Error,
    statusCode: ai_set_appointments_statusCode,
  } = useData(
    "ai_set_appointments",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

  const {
    data: ai_sales_handoffs_Data,
    status: ai_sales_handoffs_Status,
    error: ai_sales_handoffs_Error,
    statusCode: ai_sales_handoffs_statusCode,
  } = useData(
    "ai_sales_handoffs",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  );

/*   const {
    data: ai_assisted_sales_Data,
    status: ai_assisted_sales_Status,
    error: ai_assisted_sales_Error,
    statusCode: ai_assisted_sales_statusCode,
  } = useData(
    "ai_assisted_sales",
    {
      start_date: formatToDateString(dateRange.startDate),
      end_date: formatToDateString(dateRange.endDate),
    },
    [dateRange, accountId]
  ); */

  return (
    <div className="DashboardCardArea">
      <DashboardCard
        type="square"
        objectKey="total_leads_worked"
        status={total_leads_worked_status}
        error={total_leads_worked_error}
        dataPrimary={total_leads_worked_data}
        statusCode={total_leads_worked_statusCode}
      />
      <DashboardCard
        type="square"
        objectKey="total_messages_sent"
        status={total_messages_sent_Status}
        error={total_messages_sent_Error}
        dataPrimary={total_messages_sent_Data}
        statusCode={total_messages_sent_statusCode}
      />
      <DashboardCard
        type="square"
        objectKey="new_leads_engaged"
        status={new_leads_engaged_Status}
        error={new_leads_engaged_Error}
        dataPrimary={new_leads_engaged_Data}
        statusCode={new_leads_engaged_statusCode}
      />
      <DashboardCard
        type="square"
        objectKey="existing_leads_engaged"
        status={existing_leads_engaged_Status}
        error={existing_leads_engaged_Error}
        dataPrimary={existing_leads_engaged_Data}
        statusCode={existing_leads_engaged_statusCode}
      />
      <DashboardCard
        type="square"
        objectKey="ai_set_appointments"
        status={ai_set_appointments_Status}
        error={ai_set_appointments_Error}
        dataPrimary={ai_set_appointments_Data}
        statusCode={ai_set_appointments_statusCode}
      />
      <div className="twinCardContainer">
        <DashboardCard
          type="twin"
          objectKey="ai_sales_handoffs"
          status={ai_sales_handoffs_Status}
          error={ai_sales_handoffs_Error}
          dataPrimary={ai_sales_handoffs_Data}
          statusCode={ai_sales_handoffs_statusCode}
        />
{/*         <DashboardCard
          type="twin"
          renderType="ai_assisted_sales"
          status={ai_assisted_sales_Status}
          error={ai_assisted_sales_Error}
          dataPrimary={ai_assisted_sales_Data}
          statusCode={ai_assisted_sales_statusCode}
        /> */}
      </div>
    </div>
  );
};

export default DashboardCards;
